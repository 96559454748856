import './box.css';
export default function Box({name,url,icon,children}) {
    return(
        <a href={url}>
        <div className="box">
        <div className="namediv align-middle">
           <img className="iconimg" src={icon}/><span className="nametext">{name}</span>
        </div>
        <div className="textdiv">
          {children}
        </div>
      </div>
      </a>
    );
}