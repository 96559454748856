import '../App.css';
import Box from '../components/box';
import { Routes, Route, Link } from "react-router-dom";
import { useState } from 'react';
function Start() {
  const [loaderror, setLoadError] = useState(false);
  function loadingpicerror() {
    setLoadError(true);
  }

  return (
    <div id="app" className="ml-5 mr-5 mt-5">
      <div className="pl-28 pr-28 text-center">
        <img src="/pics/Refratechnik.svg" className="w-2/3 mb-10 inline-block" />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
        <Box icon="/pics/outlook.png" name="Microsoft Outlook" url="https://outlook.office.com">
          Mit der Outlook Web Application können Sie Ihre Refratechnik E-Mails abrufen.
        </Box>
        <Box icon="/pics/outlook.png" name="OWA" url="https://owa.refra.com">
          Outlook für Mitarbeiter die Excel, Word und weitere Office Programme mit Lizenz nutzen.
        </Box>
        <Box icon="/pics/refranet.png" name="Refranet" url="https://refra.coyocloud.com">
          Im Refranet finden Sie die aktuellen Neuigkeiten rund um Refratechnik
        </Box>
        <Box icon="/pics/successfactors.png" name="SuccessFactors" url="https://performancemanager5.successfactors.eu/login?company=Refratechnik&loginMethod=SSO">
          Über SuccessFactors können Sie:<br />
          • Urlaub beantragen<br />
          • Stammdaten ändern<br />
          • Digitale Personalakte einsehen
        </Box>

        <Box icon="/pics/sam.png" name="SAM" url="https://refratechnik.secova.de">
          In Sam finden Sie:<br />
          • Unterweisungen<br />
          • Vorsorgen
        </Box>


        <Box icon="/pics/schichtplan.png" name="KnowBe4" url="https://eu.knowbe4.com">
          In KnowBe4 finden Sie die IT-Sicherheitsschulungen
        </Box>
        <Box icon="/pics/schichtplan.png" name="MFA" url=" https://aka.ms/mfasetup">
          Über die Multifactor-Authentifizierung können Sie Ihre erste Einrichtung für das Refra-Netzwerk vornehmen
        </Box>
        <Box icon="/pics/schichtplan.png" name="Service Desk IT" url="https://servicedesk.refra.com/wm">
          Im Self Service Portal können Sie: <br />
          • Produkte oder Leistungen beantragen<br />
          • IT ServiceTickets einreichen <br />
          • offene Anfragen verwalten <br />
        </Box>
        <Box icon="/pics/schichtplan.png" name="Schichten">
          <u>Göttingen:</u><br/>
          {!loaderror ? <Link to="aufbereitung">• Aufbereitung</Link> : <a href="https://refratechnikde.sharepoint.com/sites/Test524/Shared%20Documents/General/Aufbereitung.png">• Aufbereitung</a>}<br />
          {!loaderror ? <Link to="werkstatt">• Werkstatt</Link> : <a href="https://refratechnikde.sharepoint.com/sites/Test524/Shared%20Documents/General/Werkstatt.png">• Werkstatt</a>}<br />
          {!loaderror ? <Link to="produktion">• Produktion</Link> : <a href="https://refratechnikde.sharepoint.com/sites/Test524/Shared%20Documents/General/Produktion.png">• Produktion</a>}<br />
          <u>Gochsheim:</u><br/>
          {!loaderror ? <Link to="gochsheim_aqwm">• Aufbereitung, QS, Werkstatt, Maurer</Link> : <a href="https://refratechnikde.sharepoint.com/sites/Test524/Shared%20Documents/General/Aufbereitung,%20QS,%20Werkstatt,%20Maurer.png">• Aufbereitung, QS, Werkstatt, Maurer</a>}<br />
          {!loaderror ? <Link to="gochsheim_oep">• Ofen, Entladung, Pressen</Link> : <a href="https://refratechnikde.sharepoint.com/sites/Test524/Shared%20Documents/General/Ofen,%20Entladung,%20Pressen.png">• Ofen, Entladung, Pressen</a>}<br />
        </Box>
        <Box icon="/pics/corporatebenefitslogo.png" name="Corporate Benefits" url="https://refratechnik.mitarbeiterangebote.de">
          Über Corporate Benefits erhalten Sie exklusive Rabatte, die Sie in Online-Shops und Filialen einlösen können.
        </Box>
      </div>
      <br /><br />
      <img className='checkpic' onError={loadingpicerror} src={"https://refratechnikde.sharepoint.com/sites/Test524/Shared%20Documents/General/Produktion.png" + "?" + new Date().getMilliseconds()} />
    </div >
  );
}

export default Start;
