import Box from '../../components/box';
import { Link } from "react-router-dom";
import { useState } from 'react';
import './pagecontent.css';
function Pagecontent({imgurl,name}) {
  return (
    <div id="app" className="ml-1 mr-1 mt-5">
        <Link to="/">
              <div className="pl-1 pr-1 text-center">
	<img src="/pics/Refratechnik.svg" className="mb-1 inline-block w-full lg:w-auto" />
    <div>Zurück zur Startseite</div>
    <br/>
  </div>
  </Link>
          <div >
  <Box name={name}>
   <a href={imgurl + "?"+ new Date().getMilliseconds()}><img className='picbox'  src={imgurl + "?"+ new Date().getMilliseconds()} /></a>
    </Box>
    </div>
</div>

  );
}

export default Pagecontent;
