import './App.css';
import Aufbereitung from './pages/aufbereitung';
import { Routes, Route, Link } from "react-router-dom";
import Start from './pages/start';
import Produktion from './pages/produktion';
import Werkstatt from './pages/Werkstatt';
import Gochsheim_AQWM from './pages/gochsheim_aqwm';
import Gochsheim_OEP from './pages/gochsheim_oep';
function App() {
  /*
  Für F3 User
  */
  return (
    <div id="app" className="ml-1 mr-1 mt-1">
<Routes>
        <Route path="/" element={<Start />} />
        <Route path="aufbereitung" element={<Aufbereitung />} />
        <Route path="produktion" element={<Produktion />} />
        <Route path="werkstatt" element={<Werkstatt />} />
        <Route path="gochsheim_aqwm" element={<Gochsheim_AQWM />} />
        <Route path="gochsheim_oep" element={<Gochsheim_OEP />} />
      </Routes>
	</div>
  );
}

export default App;
