import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { InteractionType } from "@azure/msal-browser";
import { BrowserRouter } from "react-router-dom";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { MsalProvider } from "@azure/msal-react";
import { Configuration,  PublicClientApplication } from "@azure/msal-browser";
import Loader from './components/loader/loader';




//@ts-ignore
function ErrorComponent({error}) {
  return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
  return <Loader text="REFRA Authentication.." />
}



const authRequest = {
  scopes: ["openid", "profile"]
};
const pca = new PublicClientApplication(window.config.AUTHCONFIGURATION);


ReactDOM.render(
  <React.StrictMode>
       <MsalProvider instance={pca}>
       <MsalAuthenticationTemplate 
interactionType={InteractionType.Redirect} 
authenticationRequest={authRequest} 
errorComponent={ErrorComponent} 
loadingComponent={LoadingComponent}>
       <BrowserRouter>
    <App />
      </BrowserRouter>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.register();
reportWebVitals();
